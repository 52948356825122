.map-viewer .row {
  flex-wrap: unset;
  height: 60px;
  background-color: #0d1a40;
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.map-syngenta-logo {
  margin-left: 10px;
  cursor: pointer;
}
.map-profile-icon {
  border: none;
  background-color: #006d82;
  height: 24px;
  width: 24px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  position: relative;
  right: 2%;
}
.map-mobile-yearAndSearch {
  display: flex;
  justify-content: center;
  width: calc(100% - 24px);
  height: 48px;
  background-color: white;
  border-radius: 24px;
  margin-top: 5px;
  margin-left: 0;
  position: fixed;
  top: 90px;
}
.map-smallDevice-yearAndSearch {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  height: 48px;
  background-color: white;
  border-radius: 24px;
  margin-top: 5px;
  margin-left: 0px;
  position: fixed;
  top: 90px;
}
.map-yearAndSearch {
  display: flex;
  justify-content: space-between;
  width: 472px;
  height: 48px;
  background-color: white;
  border-radius: 24px;
  margin-top: 6px;
  z-index: 9;
  left: 484px;
}
.map-yearAndSearch-tablet {
  display: flex;
  justify-content: space-between;
  /* width: calc(100% - 24px); */
  height: 48px;
  background-color: white;
  border-radius: 24px;
  margin-top: 5px;
  z-index: 9;
}
.map-yearAndSearch-lanscape {
  display: flex;
  width: 409px !important;
  height: 48px;
  background-color: white;
  border-radius: 24px;
  z-index: 9;
}
.map-dropdown {
  position: relative;
}
.map-year-dropdown {
  height: 48px;
  width: 116px !important;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  background-color: #f9f9f9 !important;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 22px;
}
.map-dropdownText {
  color: #3d4c0a;
  font-size: 12px;
  padding-right: 20px;
  font-family: Helvetica;
}
.map-input-parent {
  width: 356px;
  height: 48px;
  position: relative;
}
.map-input-field {
  width: 100%;
  height: 100%;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  outline: none;
  font-size: 12px;
  color: #3d4c0a;
  border: 1px solid #f9f9f9;
}
.map-input-search {
  position: absolute;
  right: 25px;
  top: 10px;
}
.map-viewer .list-group-item {
  font-size: 0.875rem;
  background-color: #FFFFFF;
  color: #000000;
  border-bottom: 1px solid #EDEDED;
}
.MapD-feedback{
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #ffffff;
  font-weight: 700;
}
.map-desktopPro-vector{
  display: flex;
  gap: 9px;
  margin-Left:-6px;
}
.map-mobilePro-vector{
  display: flex;
  gap: 9px;
  margin-Left:-6px;
}
.logout-Mobile {
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #0D1A40;
  padding-left: 0;
  font-weight: 700;
}

.mapMobile-feedback{
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #ffffff !important;
  font-weight: 700;
}

/*..........Trail Card UI.......*/
.map-card-logo {
  border: none;
  background-color: #F1F2F3;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #0D1A40;
  font-size: 8px;
  font-weight: 700;
}
.map-card-bottomLogo {
  border: none;
  background-color: #3d4c0a !important;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  font-size: 12px;
}
.desktop-trailui-card {
  position: fixed;
  top: 60px;
  height: calc(100vh - 60px);
  background-color: #f9f9f9;
  z-index: 999;
}
.landscape-trialui-card {
  position: fixed;
  top: 80px;
  height: calc(100vh - 80px);
  background-color: #f9f9f9;
  z-index: 999;
}
.map-card-FirstLine {
  font-weight: 700 !important;
  font-size: 14px;
  color:#0D1A40
}

.trail-card {
  width: 354px;
  height: 103px;
  color:#0D1A40;
  min-width: 354px;
  padding: 0px;
  border: 0.5px solid #EDEDED;
}
.map-name {
  font-weight: 700 !important;
  font-size: 12px;
}
.trial-bottom-row {
  background-color: #f9f9f9;
}
.trail-card.trial-card-mobile.card {
  margin-right: 5px;
}
.result-text {
  font-size: 10px;
  font-weight: 700;
  color: #0D1A40;
}
.trial-card-mobile {
  box-shadow: none !important;
  border: 1px solid #e0e5cf;
}
.trial-card-wrapper::-webkit-scrollbar {
  height: 5px;
}

.toggle-switch-new-landing {
  color: white;
  border-radius: 20px;
  font-size: 12px;
  margin-left: 6px;
  width: 80px;
  height:30px;
  cursor: pointer;
  z-index: 99;
  display: inline-flex;
  align-items: center;
}
.toggle-switch-new-landing-tablet {
  color: white;
  border-radius: 20px;
  font-size: 12px;
  margin-right: 5px;
  width: 75px;
  cursor: pointer;
  z-index: 99;
}

.map-viewer .row:after {
  display: none;
}
.toggle-switch-new-landing-mobile {
  color: white;
  border-radius: 20px;
  font-size: 12px;
  margin-left: 6px;
  width: 80px;
  cursor: pointer;
  z-index: 99;
}
.landing-mobiletopbar .row {
  flex-wrap: unset;
  height: 80px !important;
  background-color: #0d1a40;
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.map-viewer .dropdown {
  background-color: white;
  border-top-left-radius: 24px;
}
.map-viewer .newDropdown {
  background-color: transparent;
  border-top-left-radius: none;
}
.map-viewer .dropdown-menu-show {
  min-width: 80px !important;
}

.map-viewer .dropdown-menu {
  float: left;
  font-size: 1rem;
  color: #212529;
  list-style: none;
  background-color: #fff;
  max-width: 116px;
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  z-index: 99999;
  width: 100%;
  box-shadow: none !important;
  margin-top: -3px !important;
}
.map-viewer .select-year {
  font-size: 12px;
  color: #0D1A40;
  font-weight: 700;
  font-family: Helvetica;
  line-height: 13.8px;
  text-align: center;
  padding: 10px 10px 5px 10px;
}
.map-viewer .down-icon {
  margin-left: 10px !important;
  width: 100% !important;
  height: 16px;
}

.search-menu-list {
  width: 472px;
  background-color: white;
  position: absolute;
  z-index: 8;
  top: 33px;
  max-height: 420px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}

.search-tablet-menu-list {
  width: calc(100% - 43px);
  background-color: white;
  position: absolute;
  z-index: 8;
  top: 33px;
  max-height: 420px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}
.search-menu-heading {
  font-weight: 700;
  color: #0D1A40;
  font-size: 12px;
  padding-left: 20px;
  width: 75px;
}
.search-menu-data {
  font-weight: 400;
  font-size: 12px;
  color:#0D1A40;
}
.search-mobile-menu-list {
  width: calc(100% - 24px);
  background-color: white;
  position: fixed;
  z-index: 999;
  top: 130px;
  max-height: 420px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}
.search-smallDevice-menu-list {
  width: calc(100% - 24px);
  background-color: white;
  position: fixed;
  z-index: 998;
  top: 125px;
  max-height: 320px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}
.search-landscape-menu-list {
  width: 409px;
  background-color: white;
  position: fixed;
  z-index: 8;
  top: 45px;
  max-height: 320px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}
.search-loading-text {
  font-size: 12px;
  font-weight: 700;
  color: #0D1A40;
}
.no-data-text {
  font-size: 12px;
  color:#FF3F3F;
  font-weight: 400;
}
.new-layout-loader {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100% !important;
  opacity: 1;
  position: absolute;
  z-index: 9999;
  padding-bottom: 5%;
}
.search-result-bar {
  position: fixed;
  top: 60px;
  left: 0;
  height: 30px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 998;
}
.search-result-bar-with-trials {
  left: 354px;
  width: calc(100% - 354px);
}
.map-viewer a.leaflet-control-rotate-toggle.leaflet-disabled {
  display: none;
}
.body-wrapper {
  width: 100%;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
}
.map-viewer .trial-cards-at-bottom-with-result .leaflet-control-zoom.leaflet-bar.leaflet-control {
  margin-bottom: 70px;
}
.map-viewer .trial-cards-at-bottom-with-result .zoom-label.leaflet-control {
  margin-bottom: 70px;
}
.map-viewer
  .trial-cards-at-bottom.trial-cards-at-bottom-with-result
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
  margin-bottom: 190px;
}
.map-viewer .trial-cards-at-bottom.trial-cards-at-bottom-with-result .zoom-label.leaflet-control {
  margin-bottom: 190px;
}
.welcome-wrapper {
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.welcome-primary-text {
  font-size: 36px;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.welcome-secondary-text {
  font-size: 32px;
  font-weight: 400;
}
.welcome-wrapper-mobile .welcome-secondary-text {
  font-size: 24px;
}
.welcome-wrapper-mobile .welcome-primary-text {
  justify-content: center;
  padding-left: 35px;
}
.welcome-wrapper.welcome-wrapper-mobile {
  padding-bottom: 30%;
}
.welcome-wrapper.welcome-wrapper-tablet {
  padding-bottom: 30%;
}
.trial-cards-at-left {
  position: absolute;
  left: 354px;
  width: calc(100vw - 354px) !important;
}
.trial-cards-at-bottom {
  height: calc(100% - 140px) !important;
}
.trial-cards-at-bottom.trial-cards-at-bottom-with-result {
  height: calc(100% - 160px) !important;
}
/*----------------Refined Search------------*/
.refined-search {
  position: fixed;
  right: 0;
  top: 60px;
  z-index: 9;
  width: 354px;
  background-color: #ffffff;
  height: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
.refined-search-div {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 19px;
  padding-top: 12px;
}

.refined-search-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 700;
  color: #0D1A40;
}

.refined-search-ClearAll {
  font-family: Helvetica;
  font-size: 14px;
  font-weight:700;
  color: #0D1A40;
  text-decoration: underline;
  text-decoration-color: #0D1A40;
}
.refined-accordion .accordion-button {
  border: none !important;
  box-shadow: none !important;
}
.refined-accordion .refined-accodion-heading {
  float: left;
  padding-left: 18px;
  color: #0D1A40;
  font-size: 14px;
  font-family: Helvetica;
}
.refined-accordion .refined-icon-up-down {
  float: right;
  padding-right: 13px;
}
.refined-accordion .accordion-button .collapse-icon {
  width: 20px;
  height: 18px;
  fill: #0D1A40;
  margin-top: 0 !important;
}
.refined-search-cancel {
  border: none;
  background-color: none !important;
  background-color: white !important;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 16px;
  color: #0D1A40 !important;
  box-shadow: none !important;
  height: 40px;
  width: 96px;
}
.refined-search-apply {
  border: none;
  background-color: none !important;
  background-color: #0D1A40 !important;
  border-radius: 20px;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 16px;
  color: #ffffff !important;
  box-shadow: none !important;
  height: 40px;
  width: 96px;
}

.refinedsearch-search-icon {
  position: absolute;
  right: 6%;
  top:10px;
}
.refinedsearch-input::placeholder{
  padding-left: 0px;
  color:#0D1A40
}
.refinedsearch-input{
  border:none;
  height: 40px;
  background-color: #F1F2F3;
  margin-left: 25px;
  margin-top: 6px;
  border-radius: 20px;
  font-size: 12px;
  color: #0D1A40;
  font-weight: 400;
  padding-left: 15px;
}

.refinedsearch-label {
  margin-left: 25px;
  margin-top: 10px;
}
.refinedsearch-checkbox-icon {
  width: 18px;
  height: 18px;
}
.reinedsearch-checkbox-data {
  padding-left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #0D1A40;
}
.refined-landscape-search {
  position: fixed;
  top: 80px !important;
  z-index: 9;
  width: 359px !important;
  background-color: #ffffff;
  height: 100%;
}
.data-button {
  background-color: transparent;
  color: #E08330;
  border: 1px solid #E08330;
  border-radius: 24px;
  width: 72px;
}
.map-viewer .leaflet-popup-content-wrapper {
  border-radius: 0;
}
.map-viewer .leaflet-popup-content {
  width: 348px !important;
  height: 104px !important;
  margin: 0px;
}
.map-viewer .leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: -10px;
  left: -8px;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px / 24px;
  color: #0D1A40!important;
  text-decoration: none;
  background: white;
  border-radius: 27px;
  border: 1px solid #0D1A40;
  padding: 4px;
  font: 16px/14px Tahoma, Verdana, sans-serif !important;
}
.map-viewer
  .trial-cards-at-bottom.trial-cards-at-bottom
  .leaflet-control-zoom.leaflet-bar.leaflet-control {
  margin-bottom: 190px;
}
.map-viewer .trial-cards-at-bottom.trial-cards-at-bottom .zoom-label.leaflet-control {
  margin-bottom: 190px;
}
.map-viewer .leaflet-left .leaflet-control{
  border:none !important;
}
.map-viewer .bookmark {
  background-color: transparent;
  border: none;
}
.no-bookmark-data-text {
  font-size: 12px;
  color: #293306;
  font-weight: 700;
  font-family: Helvetica;
}
.cardBackground {
  background-color: #EDEDED;
} 
.noBackgroundCard {
  background-color: none;
}
.landing-filter-icon {
  position: fixed;
  float: right;
  right: 10px;
  color: #E08330;
} 
.landscape-filter-icon{
  position: fixed;
  float: right;
  left: 315px;
}
.desktopMapTrialSummary .modal-content {
  position: absolute;
  top: 65px;
  min-width: calc(100vw - 360px);
  height: 555px;
  font-size: 0.75rem;
  left: 0px;
  z-index: 0;
}
 
.desktopMapTrialSummary .modal-dialog {
  justify-content: center;
  min-height: 100px !important;
  position: absolute;
  left: 360px;
}
.tabMapTrialSummary .modal-dialog {
  justify-content: center;
  min-height: 100px !important;
  top: 15%;
  max-width: 100vw !important;
}
.tabMapTrialSummary .modal-content {
  width: 100vw;
  height: 680px;
  font-size: 0.75rem;
  z-index: 0;
  margin: auto;
}
.landScapeMapTrialSummary .modal-dialog {
  display: contents;
  padding-left: 0 !important;
}
.mobileMapTrialSummary .modal-dialog {
  height: 100vh !important;
  margin: 0px;
}

.landScapeMapTrialSummary {
  z-index: 9999 !important;
}
.mobileMapTrialSummary {
  padding-left: 0 !important;
  z-index: 9999 !important;
}
.mobileMapTrialSummary .modal-content {
  position: absolute;
  width: 100vw;
  height: 100vh !important;
  overflow-y: scroll;
  font-size: 0.75rem;
  z-index: 0;
  margin: auto;
}
.map-close-button{
    cursor: pointer;
    font-size: 20px;
  color:#E08330 !important;
 
}
.map-data-button {
  background-color: transparent !important;
  color: #E08330 !important;
  border: 1px solid #E08330 !important;
  border-radius: 16px;
  box-shadow:none !important;
  height: 20px;
  width: 72px;
  font-size: 12px;
  font-weight:400;
  font-family:Helvetica;
  padding-left: 16px;
}
 
.row:after{
  padding: 0px !important;
}
.tabMapTrialSummary .modal-body, .mobileMapTrialSummary .modal-body, .landScapeMapTrialSummary .modal-body, .desktopMapTrialSummary .modal-body {
  padding: 0px !important;
}
.landing-page-toggle-switch {
  margin-top: 0px !important;
  margin-right:10px;
  height : 30px;
  width: 118px;
  background-color: #255AF6 !important;
  border-color: #255AF6 !important;
  font-weight: 600;
  border-radius: 20px;
}
.map-toggle-switch-Viewer, .map-toggle-switch-Viewer-MT {
  margin-top: 0px !important;
  margin-right:10px;
  height : 30px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.map-search::placeholder{
  color:#0D1A40;
}
.trailcardBackground{
  border-left: 8px solid #E08330;
  border-top: 1px solid #E08330;
  border-right: 1px solid #E08330;
  border-bottom: 1px solid #E08330;
}

.map-smalldevicelandscape-yearAndSearch{
  display: flex;
  width: 370px !important;
  height: 48px;
  background-color: white;
  border-radius: 24px;
  z-index: 9;
}
.small-device-landscape-search-menu-list{
  width: 370px !important;
  background-color: white;
  position: fixed;
  z-index: 8;
  top: 45px;
  max-height: 320px;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}

.map-viewer .leaflet-popup-content p {
  margin: 18px 0;
  display: none;
}

.landing-page-tooltip {
  color: white;
  margin-left:8px;
  top: 31px !important;
  z-index: 998 !important;
}

.landing-page-tooltip.landing-page-tooltip-landscape {
  margin-left: 3px;
}

.landing-page-tooltip .tooltip-inner {
    background-color: #255AF6 !important;
    font-size: 12px;
    font-family: Helvetica;
    width: 179px !important;
    max-width: 180px !important;
    height: 103px;
    font-size: 11px;
    margin: auto;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-mobile-page-tooltip{
  color: white;
  margin-bottom: 35px;
  margin-left: -159px;  
}

.landing-mobile-page-tooltip .tooltip-inner {
  background-color:#255AF6!important;
  font-size: 12px;
  font-family: Helvetica;
  width:179px;
  height:103px;
  padding-top:12px;
  text-align: left;
  display: flex;
  align-items: center;
}
.tooltip-text{
  display: flex;
  margin-left: 10px;
  text-align: left;
}
.description-box {
  resize: none;
  font-size: 12px;
  color: #0D1A40;
  border-radius: 10px;
}

.tablet-trial-report-modal .modal-content{
  min-width: 709px !important;
  min-height: 408px !important;
}

.tablet-trial-report-modal .modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tablet-success-modal .modal-content{
  min-width: 560px !important;
  min-height: 384px !important;
}

.mobile-general-trial-report-modal .modal-content{
  min-width: 360px !important;
  min-height: 423px !important;
}

.mobile-landscape-general-trial-report-modal .modal-content{
  min-width: 394px !important;
  min-height: 340px !important;
}

.mobile-landscape-general-trial-report-modal .report-modal-body{
  max-height: none !important;
}


.mobile-success-modal .modal-content{
  min-width: 414px !important;
  min-height: 384px !important;
}

.report-modal-body{
  overflow-x: hidden;
}
