#root:has(.DFS-row) {
  background-color: #F9F9F9;
}


.DFS-inseasonText-div{
  margin-top: 15px;
}

.DFS-inseasonText{
  color: white;
  font-size: 14px;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
}

.DFS-logout{
  margin-left: 0px;
  margin-top: 15px;
  border: none;
  background-color: #006D82;
  height: 32px;
  width: 32px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

.DFS-logout-profile{
  position: absolute;
  right: 54px;
  z-index: 99;
  min-width: 300px;
  top: 60px;
}

.DFS-droneFlightSites-text{
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  color: #0D1A40;
  font-size: 16px;
}

.DFS-droneFlightSites-div{
  margin-top: 70px;
  margin-left: 45px;
}

.sites-search{
  width: 280px;
  height: 48px;
  border-radius: 24px;
  outline: 1px;
  font-size: 14px;
  color: #0D1A40;
  border: 1px solid #EDEDED;
  background-color: #FFFFFF;
}

.sites-search::placeholder{
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  color: #0D1A40;
  padding-left: 10px;
}

.sites-search-icons{
  position: absolute;
  right: 13%;
  top: 10px;
}

.DFS-new.show.dropdown{
  display:flex;
}

.DFS-select-year{
  font-size: 12px;
  color: #0D1A40;
  margin-bottom : 5px;
  text-align: right;
  font-weight:bold;
  font-family: Helvetica, sans-serif;
  margin-left : -10px
}


.DFS-accordion .accordion-button {
  border: 1px solid #ededed !important;
  box-shadow: none !important;
  background-color: #F9F9F9 !important;
 
}
.DFS-accordion{
  margin:12px 26px 40px 26px;
}
.DFS-accodion-heading {
  float: left;
  padding-left: 18px;
  color: #293306;
  font-size: 14px;
  font-family: Helvetica, sans-serif;
  margin-bottom: none;
}
.DFS-icon-up-down {
  float: right;
  padding-right: 13px;
  padding-top: 10px;
}
.DFS-accordion .accordion-button .collapse-icon {
  width: 20px;
  height: 18px;
  fill: #293306;
  margin-top: 0 !important;
} 

.DFS-card {
  box-shadow: none!important;
  text-align: center;
  background-color: #ffffff;
  border:none !important;
  border-right: 1px  solid #ededed !important;
  border-left: 1px  solid #ededed !important;
  border-bottom: 1px  solid #ededed !important;
  padding:0 14px 30px 16px !important;
}
.DFS-card-body{
  font-size: 12px;
  left: 1;
  padding-left: 15px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important; 
  
}
.DFS-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -50px!important;
  margin-left: -15px;
  justify-content: space-between;
  margin-bottom: -21px;
}

.DFS-body-date{
 margin-bottom: 6px;
 margin-top:6px;
 color: #0D1A40;
 font-size: 12px;
}

.DFS-year-dropdown {
  height: 48px;
  width: 116px !important;
  border-radius: 24px;
  background-color: #FFFFFF !important;
  border: 1px solid #EDEDED !important;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 22px;
  position: absolute;
  z-index: 99;
}
.droneFlights-drop .dropdown-menu-show{
  min-width: 80px!important;
 
}
.selects-year {
  font-size: 12px;
  color: #0D1A40;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  line-height: 13.8px;
  text-align: center;
  padding: 30px 10px 5px 10px;
}

.droneFlights-drop .dropdown-menu {
  position: absolute !important;
  font-size: 1rem;
  color: #0D1A40;
  list-style: none;
  background-color: #fff;
  max-width: 116px;
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  z-index: 9;
  right: 0.5% !important;
  top:-26px !important;

}

.DFS-new-layout-loader {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100% !important;
  opacity: 1;
  position: absolute;
  z-index: 9999;
  padding-bottom: 5%;
}

.DFS-date-spinner {
  color: #0d1a40 !important;
  width: 134px;
  height: 134px;
}

.DFS-text-bubble-div{
  position: relative;
  top: 60px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 48%;
  right: 7.5%
}

.DFS-text-bubble{
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 48px;
  background-color: #255AF6;
}

.DFS-text-bubble-data{
  align-items: center;
  font-size: 16px;
  color: white;
}

.DFS-block-collectionText-data{
  font-weight: bold;
  margin-left: 15px;
  color: #0D1A40;
  font-size: 14px;
}

.DFS-block-collectionText{
  font-weight: 400;
  color: #979797;
  font-size: 14px;
}

.DFS-profile-paper{
  position: absolute;
  right: 42%;
  top: 60px;
  background-color: rgb(13, 26, 64);
  z-index: 9999;
  min-width: 300px;
}

.DFS-profile-name{
  display: flex;
  justify-content: center;
  background-color: #0D1A40;
  color: white;
  border-bottom: 1px solid #343E5C;
}

.DFS-profile-paper .list-group-item {
  font-size: 0.875rem;
  background-color: #FFFFFF;
  color: #0D1A40;
  display: flex;
  justify-content: left;
  border-bottom: 1px solid #EDEDED;
}

.DFS-vector{
  display: flex;
  gap: 9px;
  margin-Left:-6px;
}

.DFS-profile-logout{
  display: flex;
  justify-content: center;
  background-color: #0D1A40;
}

.DFS-logout-Mobile {
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #0D1A40 !important;
  font-weight: 700;
}

.DFS-feedback{
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #ffffff !important;
  font-weight: 700;
}

.DFS-search-for-sites{
  width: 300px;
  height: 48px;
  border-radius: 24px;
  outline: 1px;
  font-size: 12px;
  color: #0D1A40;
  border: 1px solid #343E5C;
  padding-left: 6px;
}
.custom-scrollbar {
  position: relative;
  top: 55px;
  background-color: #f9f9f9;
  max-height: calc(100vh - 120px);
  overflow-y: scroll
}
.custom-scrollbar::-webkit-scrollbar{
  width: 6px;
  height: 8px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaa9a9 !important;
}

.DFS-no-data-text{
  font-size: 12px;
  color: #FF3F3F;
  font-weight: 700;
}
.submitted-button {
  font-size: 8px;
  height: 10px;
  width: 49px;
  min-width: 49px;
  border-radius: 10px;
  font-weight: 700;
  background-color: #009966;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-left: 10px;
  line-height: 10px;
}

.dfs-profile-report{
  position:relative;
  left: 40px;
}

.dfs-topBar{
  flex-wrap: unset;
  height: 60px;
  background-color: #0D1A40 !important;
  z-index:999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: "flex";
}

.dfs-general-report-modal{
  display: flex;
  position: relative;
  justify-content: flex-end;
  padding-right: 40px;
}

.dfs-profile-button{
  margin-left: 25px;
  border: none;
  background-color: #006D82;
  height: 24px;
  width: 24px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

.dfs-profile-div{
  position: absolute;
  right: 15px;
  z-index: 9999;
  min-width: 300px;
  top: 60px;
}

.dfs-list-group-item{
  display: flex; 
  gap: 10px;
  align-items: center;
}

.dfs-vector-icon{
   width: 20px; 
   height: 20px;
   margin: 2px;
}

.dfs-list-group-logout{
  display: flex;
  justify-content: center;
}

.dfs-logout-icon{
  width: 18px; 
  height: 18px;
  margin: 3px; 
  font-weight: 700; 
}